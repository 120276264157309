import axiosInstance from "@/plugins/axios";

export const login = (formData) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosInstance.post(`/api/users/login`, formData)
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};
export const logout = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosInstance.delete(`/api/users/logout`)
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};
export const getParentClient = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosInstance.get(`/api/users/parentClient`)
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};
export const getUserInfo = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosInstance.get(`/api/users/userInfo/${localStorage.getItem("clientId")}/client`)
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};
export const refreshToken = (authToken) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosInstance.post(`/api/users/refreshToken?authToken=${authToken}`);
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};
export const getUserSessionLimit = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosInstance.get(`/api/users/getUserSessionLimit`)
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};
export const saveOAtuh2Uiid = (body) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosInstance.post(`/api/users/saveOAtuh2Uiid`, body)
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};
export const validateOAtuh2Uiid = (uiid, email, expiresAt) => {
    return new Promise(async (resolve, reject) => {
        const body = {
            uiid: uiid,
            email: email,
            expiresAt: expiresAt
        }
        try {
            const response = await axiosInstance.post(`/api/users/validateOAtuh2Uiid`, body)
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};
