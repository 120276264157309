<script setup>
import Menu from '@/utils/Menu';
import { getUserPermissions } from '@/utils/UtilsExports';
import { ref } from 'vue';
import AppMenuItem from './AppMenuItem.vue';

const permissions = ref([])

const menuItems = new Menu();
permissions.value = getUserPermissions(localStorage.getItem("user"));
permissions.value = permissions.value.filter(permission => {
    return permission !== '/' && permission !== 'my-tickets-reassigned';
});
const model = ref([
    {
        label: 'Home',
        items: [{ label: 'Inicio', icon: 'pi pi-fw pi-home', to: '/' }]
    },
    {
        label: 'Paginas',
        icon: 'pi pi-fw pi-briefcase',
        to: '/pages',

        items: permissions.value.map(permission => {
            const menuItem = menuItems.getMenuItem(permission);
            return {
                label: menuItem ? menuItem.label : permission,
                icon: menuItem ? menuItem.icon : '',
                to: menuItem ? menuItem.to : '/'
            };
        }),
    },
    {
        label: 'Ayuda',
        items: [{ label: 'Documentación', icon: 'pi pi-fw pi-file-word', url: 'https://www.sin-cola.com/doc/' }]
    },

]);
</script>

<template>
<ul class="layout-menu">
    <template v-for="(item, i) in model" :key="item">
        <app-menu-item v-if="!item.separator" :item="item" :index="i"></app-menu-item>
        <li v-if="item.separator" class="menu-separator"></li>
    </template>
</ul>
</template>

<style lang="scss" scoped></style>
