import { defineStore } from 'pinia';

export const useUserStore = defineStore('user', {
    state: () => ({
        user: {
            id: null,
            first_name: null,
            last_name: null,
            email: null,
            address: null,
            phone_number: null,
            nickname: null,
            type: null,
            time_zone: null,
            active: true,
            last_password_change: null
        },
    }),
    persist: true,
    actions: {
        updateUser(updatedUser) {
            console.log("save....");
            this.user = { ...this.user, ...updatedUser };
        }
    }
});
export const useMyGateStore = defineStore('myGate', {
    state: () => ({
        myGate: {
            branchId: null,
            gateId: null,
            timeZone: null,
        }
    }),
    persist: true,
    actions: {
        updateProperty(propertyName, value) {
            if (this.myGate.hasOwnProperty(propertyName)) {
                this.myGate[propertyName] = value;
                console.warn(`Property ${propertyName} does not exist in the store.`);
            }
        },
        updateMyGate(updatedMyGate) {
            console.log("save....");
            this.myGate = { ...this.myGate, ...updatedMyGate };
        }
    }
});