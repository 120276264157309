import { isBefore } from "date-fns";
import { jwtDecode } from "jwt-decode";
import { refreshToken } from "./LoginService";

export const validateToken = async () => {
    const authToken = localStorage.getItem('authToken')
    let accessToken = localStorage.getItem("accessToken")

    try {
        if (accessToken !== null && accessToken !== "null" && accessToken !== "" && accessToken !== "undefined") {
            const tokenDecode = jwtDecode(accessToken);
            const tokenDateDecode = Math.floor(tokenDecode.exp * 1000.0);
            const dateToken = new Date(tokenDateDecode);
            const today = new Date().getTime();
            const oneHourFromNow = new Date(today + (1000 * 60 * 60));
            const result = isBefore(dateToken, oneHourFromNow)
            console.log("oneHourFromNow", oneHourFromNow)
            console.log("tokenDateDecode", dateToken)
            console.log(result, "result")
            if (result) {
                const response = await refreshToken(authToken)
                localStorage.removeItem('accessToken')
                localStorage.setItem('accessToken', response.data.accessToken)
            }
        } else if (authToken !== null && authToken !== "undefined") {
            const response = await refreshToken(authToken)
            localStorage.removeItem('accessToken')
            localStorage.setItem('accessToken', response.data.accessToken)
        }
    } catch (err) {
        console.error(err);
    }
}