import axiosInstance from '@/plugins/axios';

export const clientFinAllEvents = (formData) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosInstance.get(`/api/clients/${formData.clientId}/eventsAll`)
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};

export const clientSetting = (clientId) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosInstance.get(`/api/clients/${clientId}/settings`)
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
}

export const clientFindColorsConfigOfTime = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosInstance.get(`/api/clients/colorsConfig`)
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};

export const getHierarchiesAndDivisions = (clientId) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosInstance.get(`/api/clients/${clientId}/getHierarchiesAndDivisions`);
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};

export const getMonitorData = (formData) => {
    return new Promise(async (resolve, reject) => {
        const body = {
            clientId: formData.clientId,
            hierarchyId: formData.hierarchyId,
            divisionId: formData.divisionId
        }
        try {
            const response = await axiosInstance.post(`/api/clients/monitorData`, body);
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};
