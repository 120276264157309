<script setup>
import AppMessageError from '@/components/Security/AppMessageError.vue';
import { useLayout } from '@/layout/composables/layout';
import { getUserInfo, logout } from '@/service/LoginService';
import { passwordChange } from '@/service/UserService';
import { useUserStore } from '@/store/useStore';
import Translator from '@/utils/Translator';
import { logoutClear, validaPasswordArray } from '@/utils/UtilsExports';
import { useToast } from 'primevue/usetoast';
import { computed, ref } from 'vue';
import AppConfigurator from './AppConfigurator.vue';

const toast = useToast();
const traslator = new Translator();
const userStore = useUserStore();
const { onMenuToggle, toggleDarkMode, isDarkTheme } = useLayout();
const visibleRight = ref(false);
const errorsPass = ref([]);
const errorsConfirm = ref([]);
const valid = ref(true);
const updatePasswordData = ref({
    id: userStore.user.id,
    data: {
        user: {
            password: '',
            current_password: '',
            confirmPassword: ''
        }
    }
});

// ** To concatenate the BASE_URL to images SRC property
const viteBaseUrl = import.meta.env.VITE_BASE_URL;
const logoImageSrc = computed(() => (isDarkTheme.value ? viteBaseUrl + 'demo/images/logo_sincola_blanco.png' : viteBaseUrl + 'demo/images/logo_sincola_color.png'));
// **

async function handleLogout() {
    try {
        await logout().then(async (response) => {
            logoutClear();
        });

    } catch (error) {
        console.error('Error:', error);
        logoutClear();
    }
}

function handleValidaPassword() {
    errorsPass.value = validaPasswordArray(updatePasswordData.value.data.user.password);
    errorsConfirm.value = validaPasswordArray(updatePasswordData.value.data.user.confirmPassword);
}

const updatePassword = () => {
    if (errorsPass.value.length === 0 && errorsConfirm.value.length === 0
        && updatePasswordData.value.data.user.current_password.length > 0
        && updatePasswordData.value.data.user.password.length > 0
        && updatePasswordData.value.data.user.confirmPassword.length > 0) {
        passwordChange(updatePasswordData.value)
            .then((resp) => {
                if (resp.data.errors !== null && resp.data.errors.length > 0) {
                    const msgError = traslator.translate(resp.data.errors);
                    toast.add({ severity: 'error', summary: 'Error', detail: msgError, life: 3000 });
                } else {
                    localStorage.removeItem("userInvalid");
                    //invocamos el metodo get userInfo
                    getUserInfo().then(async (response) => {
                        if (response.status === 200) {
                            userStore.updateUser(response.data.content.user);
                        }
                    });
                    console.log(resp);
                    visibleRight.value = false;
                    toast.add({ severity: 'success', summary: 'Operación exitosa', detail: 'Contraseña actualizada correctamente', life: 3000 });
                }
            })
            .catch((err) => {
                console.log(err);
                toast.add({ severity: 'error', summary: 'Error tu nueva contraseña no es valida', life: 3000 });
            });
    } else {
        valid.value = false;
    }
}
</script>

<template>
<div class="layout-topbar">
    <div class="layout-topbar-logo-container">
        <button class="layout-menu-button layout-topbar-action" @click="onMenuToggle">
            <i class="pi pi-bars"></i>
        </button>
        <router-link to="/" class="layout-topbar-logo">
            <img :src="logoImageSrc" style="margin-left: auto;margin-right: auto;max-width: 90px; max-height: 32px;"
                alt="Logo" class="w-9/12 md:w-auto" />
        </router-link>
    </div>

    <div class="layout-topbar-actions">
        <div class="layout-config-menu">
            <button type="button" v-tooltip.left="'Personalizar Claro/Oscuro'" class="layout-topbar-action"
                @click="toggleDarkMode">
                <i :class="['pi', { 'pi-moon': isDarkTheme, 'pi-sun': !isDarkTheme }]"></i>
            </button>
            <div class="relative">
                <button
                    v-styleclass="{ selector: '@next', enterFromClass: 'hidden', enterActiveClass: 'animate-scalein', leaveToClass: 'hidden', leaveActiveClass: 'animate-fadeout', hideOnOutsideClick: true }"
                    type="button" v-tooltip.left="'Personalizar Paleta/Colores'"
                    class="layout-topbar-action layout-topbar-action-highlight">
                    <i class="pi pi-palette"></i>
                </button>
                <AppConfigurator />
            </div>
        </div>

        <button class="layout-topbar-menu-button layout-topbar-action"
            v-styleclass="{ selector: '@next', enterFromClass: 'hidden', enterActiveClass: 'animate-scalein', leaveToClass: 'hidden', leaveActiveClass: 'animate-fadeout', hideOnOutsideClick: true }">
            <i class="pi pi-ellipsis-v"></i>
        </button>

        <div class="layout-topbar-menu hidden lg:block">
            <div class="layout-topbar-menu-content">
                <button v-tooltip.left=userStore.user.email type="button" class="layout-topbar-action"
                    @click="visibleRight = true">
                    <i class="pi pi-user"></i>
                    <!-- <Avatar image="https://i0.wp.com/lamiradafotografia.es/wp-content/uploads/2014/07/simpson-rock.jpg"
                        size="large" shape="circle" /> -->

                    <span>Perfil</span>
                </button>
                <button type="button" v-tooltip.left="'Cerrar sesion'" @click="handleLogout"
                    class="layout-topbar-action">
                    <i class="pi pi-power-off text-xl text-primary"></i>
                    <span>Cerrar sesion</span>
                </button>
            </div>
        </div>
    </div>
</div>
<Drawer v-model:visible="visibleRight" header="Mi perfil" position="right">
    <Divider></Divider>
    <p class="block font-bold mb-3">Nombres:{{ ' ' }}{{ userStore.user.first_name }} {{ userStore.user.last_name }}
    </p>
    <p class="block font-bold mb-3">Email:{{ ' ' }}{{ userStore.user.email }}</p>
    <Divider></Divider>
    <p class="block font-bold mb-3">Contraseña actual</p>
    <div class="mb-2">
        <Password v-model="updatePasswordData.data.user.current_password" placeholder="Contraseña actual"
            :toggleMask="true" fluid :feedback="false"></Password>
        <small v-if="!valid && !updatePasswordData.data.user.current_password"
            class="text-red-500 font-bold ">Contraseña
            actual es
            requerido.</small>
    </div>
    <div>
        <p class="block font-bold mb-3">Nueva contraseña</p>
        <Password v-model="updatePasswordData.data.user.password" fluid placeholder="Nueva contraseña"
            @input="handleValidaPassword" :toggleMask="true">
            <template #header>
                <div class="font-semibold text-xm mb-4">Elige una contraseña</div>
            </template>
            <template #footer>
                <Divider />
                <ul class="pl-2 ml-2 my-0 leading-normal">
                    <li>Al menos una minúscula</li>
                    <li>Al menos una mayúscula</li>
                    <li>Al menos una numérica</li>
                    <li>Mínimo 8 caracteres</li>
                </ul>
            </template>
        </Password>
        <small v-if="!valid && !updatePasswordData.data.user.password" class="text-red-500 font-bold">Contraseña es
            requerido.</small>
        <AppMessageError :errors="errorsPass" :valid="valid"></AppMessageError>
    </div>
    <div>
        <p class="block font-bold mb-3 my-2">Confirmación contraseña</p>
        <Password placeholder="Confirmación contraseña" maxlength="100"
            v-model="updatePasswordData.data.user.confirmPassword" :toggleMask="true" @input="handleValidaPassword"
            fluid>
            <template #header>
                <div class="font-semibold text-xm mb-4">Elige una contraseña</div>
            </template>
            <template #footer>
                <Divider />
                <ul class="pl-2 ml-2 my-0 leading-normal">
                    <li>Al menos una minúscula</li>
                    <li>Al menos una mayúscula</li>
                    <li>Al menos una numérica</li>
                    <li>Mínimo 8 caracteres</li>
                </ul>
            </template>
        </Password>
        <small v-if="!valid && !updatePasswordData.data.user.confirmPassword"
            class="text-red-500 font-bold">Confirmación
            contraseña es
            requerido.</small>
        <small v-if="!valid && updatePasswordData.data.user.password !== updatePasswordData.data.user.confirmPassword"
            class="text-red-500 font-bold">Contraseñas no coinciden.</small>
        <AppMessageError :errors="errorsConfirm" :valid="valid"></AppMessageError>
    </div>
    <br>
    <br>
    <Button label="Actualizar" @click="updatePassword" icon="pi pi-user-edit" />
</Drawer>
</template>
