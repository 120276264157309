import AppLayout from '@/layout/AppLayout.vue';
import { getUserPermissions } from '@/utils/UtilsExports';
import { ref, watch } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';

const baseUrl = import.meta.env.VITE_BASE_URL;
const urlReport = import.meta.env.VITE_URL_REPORT;
let allRoutes = [];

const router = createRouter({
    history: createWebHistory(baseUrl),
    routes: [
        {
            path: '/',
            component: AppLayout,
            children: [
                {
                    path: '/',
                    name: '/',
                    component: () => import('@/views/pages/Start.vue')
                },
                {
                    path: 'pages/dashboard',
                    name: 'dashboard',
                    component: () => import('@/views/pages/Dashboard.vue')
                },
                {
                    path: '/pages/operators',
                    name: 'operators',
                    component: () => import('@/views/pages/Operator.vue')
                },
                {
                    path: '/pages/my-clients',
                    name: 'my-clients',
                    component: () => import('@/views/pages/MyClient.vue')
                },
                {
                    path: '/pages/operated-gates',
                    name: 'operated-gates',
                    component: () => import('@/views/pages/MyGate.vue')
                },
                {
                    path: '/pages/my-branches',
                    name: 'my-branches',
                    component: () => import('@/views/pages/MyBranches.vue')
                },
                {
                    path: '/pages/my-service-provider',
                    name: 'my-service-provider',
                    component: () => import('@/views/pages/ServiceSelection.vue')
                },
                {
                    path: '/pages/manager-user',
                    name: 'manager-user',
                    component: () => import('@/views/pages/Users.vue')
                },
                {
                    path: '/pages/manager-services',
                    name: 'manager-services',
                    component: () => import('@/views/pages/MyService.vue')
                },
                {
                    path: '/pages/kiosks-monitoring',
                    name: 'kiosks-monitoring',
                    component: () => import('@/views/pages/Monitor.vue')
                },
                {
                    path: '/pages/optimal-staffing',
                    name: 'optimal-staffing',
                    component: () => import('@/views/pages/OptimalStaffing.vue')
                },
                {
                    path: '/pages/my-lines',
                    name: 'my-lines',
                    component: () => import('@/views/pages/MyLine.vue')
                },
                {
                    path: '/pages/my-appointments',
                    name: 'my-appointments',
                    component: () => import('@/views/pages/Empty.vue')
                },
                {
                    path: '/pages/my-attended-tickets',
                    name: 'my-attended-tickets',
                    component: () => import('@/views/pages/MyAttendedTickets.vue')
                },
                {
                    path: '/pages/configurator-notifications',
                    name: 'configurator-notifications',
                    component: () => import('@/views/pages/ConfiguratorNotifications.vue')
                },
                {
                    path: '/pages/reports',
                    name: 'reports',
                    component: () => import('@/views/pages/Empty.vue'),
                    beforeEnter: () => {
                        // Redirige a la URL externa
                        window.location.href = urlReport;
                        return false; // Evita que se cargue el componente
                    }
                },
                {
                    path: '/pages/documentation',
                    name: 'documentation',
                    component: () => import('@/views/pages/Empty.vue'),
                    beforeEnter: () => {
                        // Redirige a la URL externa
                        window.location.href = 'https://www.sin-cola.com/doc/';
                        return false; // Evita que se cargue el componente
                    }
                },
                {
                    path: '/pages/schedule-exceptions',
                    name: 'schedule-exceptions',
                    component: () => import('@/views/pages/ScheduleExceptions.vue')
                }
            ]
        },
        {
            path: '/:pathMatch(.*)*',
            name: 'error',
            component: () => import('@/views/pages/error/Error.vue')
        },
        {
            path: '/access-denied',
            name: 'access-denied',
            component: () => import('@/views/pages/error/Access.vue')
        },
        {
            path: '/auth/login',
            name: 'login',
            component: () => import('@/views/pages/auth/Login.vue')
        },
        {
            path: '/marketing/landing',
            name: 'marketing',
            component: () => import('@/views/pages/marketing/Products.vue')
        },
    ]
});

function checkIfUserIsAuthenticated() {
    return localStorage.getItem('authToken') !== null && localStorage.getItem('accessToken') !== null && localStorage.getItem('accessToken') !== undefined;
}

router.beforeResolve(async (to, from, next) => {
    const isAuthenticated = checkIfUserIsAuthenticated();
    const permissions = ref(await getUserPermissions(localStorage.getItem("user")));
    const publicRoutes = ['login', 'notfound', 'error', 'access-denied', 'documentation', 'marketing'];
    const privateRoutes = permissions.value; // Use the reactive reference here
    const lastPassword = localStorage.getItem("userInvalid") ? true : false;
    console.log(isAuthenticated)
    allRoutes = [...publicRoutes, ...privateRoutes];
    console.log(allRoutes)
    if (!isAuthenticated) {
        if (to.name !== 'login' && to.name !== 'marketing') {
            next({ name: 'login' });
        } else {
            next(); // Allow navigation if already on login page
        }
    } else if (lastPassword) {
        if (to.name !== '/') {
            next({ name: '/' });
        } else {
            next(); // Allow navigation if already on start page
        }
    } else if (!allRoutes.includes(to.name)) {
        next({ name: 'access-denied' });
    } else if (to.name === 'login') {
        next({ name: '/' });
    } else {
        next();
    }
});

// Watch for changes in permissions
watch(allRoutes, (newAllRoutes) => {
    console.log('Permissions updated:', newAllRoutes);
    // You can add any additional logic to handle permission updates here
});

export default router;