import { getUserSessionLimit } from '@/service/LoginService';
import { validateToken } from '@/service/TokenService';
import { logoutClear } from '@/utils/UtilsExports';
import axiosInstance from './axios';

export const interceptors = () => {
  // Interceptor de solicitud
  axiosInstance.interceptors.request.use(
    async (config) => {
      let url = config.url;
      if (!url.includes('/login') && !url.includes('/refreshToken') && !url.includes('/saveOAtuh2Uiid') && !url.includes('/validateOAtuh2Uiid')) {
        config.params = {
          ...config.params,
          authToken: localStorage.getItem("authToken"),
        }
        await validateToken();
        config.headers['Authorization'] = `Bearer ${localStorage.getItem("accessToken")}`;
        //validate first if the token is expired, if it is, request a new one first.
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  // Interceptor de respuesta
  axiosInstance.interceptors.response.use(
    (response) => {
      // Aquí puedes manejar la respuesta antes de que se pase al componente
      return response;
    },
    (error) => {
      // Manejo de errores
      if (error.response) {
        // El servidor respondió con un código de estado fuera del rango 2xx
        console.error('Error en la respuesta:', error.response.data);
        if (error.response.status === 304) {
          getUserSessionLimit().then((response) => {
            if (response.status === 401) {
              logoutClear();
            }
          }).catch(error => {
            console.error("error:", error);
            logoutClear();
          });
        }
        // Ejemplo: Redirigir a la página de login si el token ha expirado
        if (error.response.status === 401) {
          // Aquí puedes redirigir al usuario a la página de inicio de sesión o manejar el error como desees
          console.error('Token expirado, redirigiendo a login...');
          // Redirigir a la página de inicio de sesión
          logoutClear();
        }
      } else {
        // Error en la configuración de la solicitud o error de red
        console.error('Error en la configuración:', error.message);
      }
      return Promise.reject(error);
    },
  );
};