export default class Menu {
    constructor() {
        this.menuItems = {
            'my-branches': {
                to: '/pages/my-branches',
                label: 'Mis sucursales',
                icon: 'pi pi-fw pi-map',
                description: 'Visualice y gestione las  sucursales de forma rápida y sencilla'
            },
            'operators': {
                to: '/pages/operators',
                label: 'Operadores',
                icon: 'pi pi-fw pi-megaphone',
                description: 'Visualice ,gestione sus operadores entre sucursales de forma rápida'
            },
            'reports': {
                to: '/pages/reports',
                label: 'Reportes',
                icon: 'pi pi-chart-line',
                description: 'Visualice en un instante con los reportes diarios de sucursal'
            },
            'my-clients': {
                to: '/pages/my-clients',
                label: 'Mis clientes',
                icon: 'pi pi-fw pi-users',
                description: 'Gestione en tiempo real el estado de los turnos de sus clientes '
            },
            'operated-gates': {
                to: '/pages/operated-gates',
                label: 'Mis taquillas',
                icon: 'pi pi-fw pi-id-card',
                description: 'Atienda sus turnos de forma rápida y sencilla'

            },
            'my-service-provider': {
                to: '/pages/my-service-provider',
                label: 'Crear turno',
                icon: 'pi pi-fw pi-shopping-cart',
                description: 'Genere nuevos turnos en sus sucursales en un instante'
            },
            'manager-user': {
                to: '/pages/manager-user',
                label: 'Usuarios',
                icon: 'pi pi-fw pi-user-edit',
                description: 'Administre los usuarios del sistema'
            },
            'manager-services': {
                to: '/pages/manager-services',
                label: 'Servicios sucursales',
                icon: 'pi pi-fw pi-cog',
                description: 'Gestione los servicios que ofrece a sus clientes'
            },
            'dashboard': {
                to: '/pages/dashboard',
                label: 'Tablero',
                icon: 'pi pi-fw pi-chart-bar',
                description: 'Vizualice estadísticas de sus turnos y gestiones de sus operadores'
            },
            'kiosks-monitoring': {
                to: '/pages/kiosks-monitoring',
                label: 'Monitoreo',
                icon: 'pi pi-fw pi-desktop',
                description: 'Monitoree en tiempo real los kioskos de sus sucursales'
            },
            'optimal-staffing': {
                to: '/pages/optimal-staffing',
                label: 'Plantilla óptima',
                icon: 'pi pi-fw pi-book',
                description: 'Aplique AI para estimar la cantidad de turnos en fechas posteriores'
            },
            'my-lines': {
                to: '/pages/my-lines',
                label: 'Mis colas',
                icon: 'pi pi-fw pi-sort-numeric-up',
                description: 'Vizualice las colas de turnos de forma rápida y sencilla'
            },
            'my-appointments': {
                to: '/pages/my-appointments',
                label: 'Mis citas',
                icon: 'pi pi-fw pi-stopwatch',
                description: 'Programe citas para servicios de forma rápida y sencilla'
            },
            'my-attended-tickets': {
                to: '/pages/my-attended-tickets',
                label: 'Mis turnos atendidos',
                icon: 'pi pi-fw pi-sort-numeric-up',
                description: 'Vizualice turnos atendidos de forma rápida y sencilla'
            },
            'documentation': {
                to: '/pages/documentation',
                label: 'Documentación',
                icon: 'pi pi-fw pi-book',
                description: 'Visualice la documentación del aplicativo  SinCola'
            },
            'configurator-notifications': {
                to: '/pages/configurator-notifications',
                label: 'Configurador de notificaciones',
                icon: 'pi pi-fw pi-cog',
                description: 'Visualice y configure las notificaciones que esperán recibir sus clientes'
            },
            'schedule-exceptions': {
                to: '/pages/schedule-exceptions',
                label: 'Configurador de horarios',
                icon: 'pi pi-fw pi-calendar',
                description: 'Visualice y configure los horarios y sus excepciones'
            },
        };
    }

    translate(text) {
        return this.menuItems[text]?.label || text;
    }

    getMenuItem(text) {
        return this.menuItems[text] || null;
    }
}