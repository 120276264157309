import router from '@/router';
import { addDays, compareAsc } from 'date-fns';
import { format, formatInTimeZone, toZonedTime } from 'date-fns-tz';

export const getCurrentDateWithTimeZone = (timeZone) => {

    const now = new Date()
    const formattedDate = formatInTimeZone(now, timeZone, 'yyyy-MM-dd')

    return formattedDate

}
export const calculateTimeDifference = (startDate, endDate, timeZone) => {
    if (startDate === null) {
        return 'Hace mucho tiempo';
    }
    startDate = startDate.replace(' ', 'T') + 'Z';
    const now = new Date().toISOString(); // Obtener la fecha actual en formato ISO

    const startTime = new Date(startDate);
    const endTime = endDate ? new Date(endDate.replace(' ', 'T') + 'Z') : new Date(now);

    // Obtener el tiempo actual en milisegundos
    const currentTime = new Date(now);

    // Calcular las diferencias en milisegundos
    const differenceInCurrentMilliseconds = currentTime - endTime;

    // Convertir la diferencia a segundos
    const differenceInCurrentSeconds = Math.floor(differenceInCurrentMilliseconds / 1000);

    // Comparar si endDate es inferior por más de un día respecto al día actual
    if (differenceInCurrentSeconds > 86400) { // 86400 seconds in a day
        return 'Hace más de un día';
    } else if (differenceInCurrentSeconds > 172800) { // 172800 seconds in two days
        return 'Hace varios días';
    }

    // Calcular horas, minutos y segundos
    const hours = Math.floor(differenceInCurrentSeconds / 3600);
    const minutes = Math.floor((differenceInCurrentSeconds % 3600) / 60);
    const seconds = differenceInCurrentSeconds % 60;

    // Formatear el tiempo en HH:MM:SS
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
};

export function getUserPermissions(user) {
    const userData = JSON.parse(user);

    if (userData !== null && userData.user.permissions && Array.isArray(userData.user.permissions)) {
        return userData.user.permissions;
    }
    return [];
}


/**
 * Convierte una fecha a una zona horaria específica y la formatea.
 * @param {string} dateString - La fecha en formato ISO.
 * @param {string} timeZone - La zona horaria a la que se desea convertir.
 * @returns {string} - La fecha convertida y formateada.
 */
export function convertDateToTimeZone(dateString, timeZone) {
    const date = new Date(dateString);
    const zonedDate = toZonedTime(date, timeZone);
    return format(zonedDate, 'dd/MM/yyyy HH:mm:ss', { timeZone });
}

/**filter periodo dashboard */
export function getDateFilterDashboard() {
    const hoy = new Date();
    const ayer = new Date(hoy.getTime() - (24 * 60 * 60 * 1000));

    const nombresMeses = [
        "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
        "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
    ];

    return [
        { id: 1, name: `Hoy  ${hoy.getDate()} de ${nombresMeses[hoy.getMonth()]} de ${hoy.getFullYear()}` },
        { id: 2, name: `Ayer ${ayer.getDate()} de ${nombresMeses[ayer.getMonth()]} de ${ayer.getFullYear()}` },
        { id: 3, name: `Mes de ${nombresMeses[hoy.getMonth()]}` },
        { id: 4, name: `Año ${hoy.getFullYear()}` }
    ];
}

/**format date in 00:00:00 */
export function formatTime(minutes) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    const seconds = 0;

    return `${String(hours).padStart(2, '0')}:${String(remainingMinutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
};

export function formatTimeFromSeconds(totalSeconds) {
    const hours = Math.floor(totalSeconds / 3600);
    const remainingMinutes = Math.floor((totalSeconds % 3600) / 60);
    const remainingSeconds = Math.floor(totalSeconds % 60);

    return `${String(hours).padStart(2, '0')}:${String(remainingMinutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
}

export function timeToMinutes(time) {
    // Split the time string into parts
    const parts = time.split(':');

    // Ensure there are exactly 3 parts (hh:mm:ss)
    if (parts.length !== 3) {
        throw new Error("Invalid time format. Use 'hh:mm:ss'.");
    }

    // Convert parts to numbers
    const hours = Number(parts[0]);
    const minutes = Number(parts[1]);
    const seconds = Number(parts[2]);

    // Check if the values are valid numbers
    if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
        throw new Error("One of the values is not a valid number.");
    }

    // Calculate total minutes
    return (hours * 60) + minutes + (seconds / 60);
}

function convertirUTC(fechaSinFormato, timeZone) {
    // Agregar 'T' y 'Z' a la cadena para que sea compatible con el objeto Date
    const fechaUTC = fechaSinFormato.replace(' ', 'T') + 'Z';

    // Crear un objeto Date a partir de la fecha en UTC
    const fecha = new Date(fechaUTC);

    // Definir opciones para la conversión
    const opciones = {
        timeZone: timeZone,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
    };

    // Convertir a la zona horaria especificada
    return fecha.toLocaleString(undefined, opciones); // Usar undefined para locale
}

export function formatearFecha(dateString) {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("es-ES", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
    }).format(date);
}

export function sortByParameter(arr, order) {
    const arrOrder = ["ns", "tme", "tmo", "ns", "tme", "tmo"];
    if (order === 0 || order === 4 || order === 5) {
        //order minor
        return arr.sort((a, b) => a[arrOrder[order]] - b[arrOrder[order]]);
    } else {
        //order maijor
        return arr.sort((a, b) => b[arrOrder[order]] - a[arrOrder[order]]);
    }
}

export function concatenateReasons(reasons) {
    return reasons.join('|');
}

export function transformJsonObject(obj) {
    const newObj = { ...obj };

    for (const key in newObj) {
        if (Array.isArray(newObj[key])) {
            // Si es un array, unir los elementos con '|'
            newObj[key] = newObj[key].join('|');
        }
    }

    return newObj; // Devolver la nueva copia transformada
}

export const convertToMinutes = (timeArray) => {
    console.log("timeArray" + timeArray)
    if (timeArray !== undefined) {
        return timeArray?.map(time => {
            const [hours, minutes, seconds] = time.split(':').map(Number);
            return hours * 60 + minutes + seconds / 60;
        });
    }
};

export const logoutClear = () => {
    localStorage.removeItem("sso");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("clientId");
    localStorage.removeItem("authToken");
    document.cookie = "cross_domain_id=; max-age=0; path=/;";
    localStorage.removeItem("user");
    localStorage.removeItem("settings");
    localStorage.removeItem("myGate");
    router.push("/auth/login");
    location.reload();
}

export const getStatusLabel = (data) => {
    if (data && data.name) {
        return 'success';
    } else {
        return 'danger';
    }
}

export const formatDateWithTimeZone = (date, timeZone) => {
    const formattedDate = formatInTimeZone(new Date(date), timeZone, 'yyyy-MM-dd');
    return formattedDate;
}

export function maskNumber(numStr, mask) {
    if (mask !== undefined && mask !== null && numStr !== undefined && numStr !== null && mask === true) {
        if (numStr.length < 8) {
            return numStr; // Devuelve el número original si no tiene suficiente longitud
        }
        // Enmascarar el número
        const masked = numStr.slice(0, 3) + '****' + numStr.slice(-4);
        return masked;
    } else {
        return numStr;
    }
}

export const formatHours = (date) => {
    const parsedDate = new Date(date);
    if (isNaN(parsedDate)) {
        throw new Error('Fecha no válida');
    }
    const hours = String(parsedDate.getHours()).padStart(2, '0');
    const minutes = String(parsedDate.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
}

export const setStartOfDay = (date) => {
    // Ajustar la fecha a la hora 0:00 (inicio del día)
    date.setHours(0, 0, 0, 0);
    return date;
}

export const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
}

export function validaPassword(cadena) {
    if (cadena !== undefined && cadena !== null) {
        const tieneMinusc = /[a-z]/.test(cadena); // Al menos una minúscula
        const tieneMayusc = /[A-Z]/.test(cadena); // Al menos una mayúscula
        const tieneNumero = /\d/.test(cadena); // Al menos un número
        const longitudValida = cadena.length >= 8; // Mínimo 8 caracteres

        return tieneMinusc && tieneMayusc && tieneNumero && longitudValida;
    } else {
        return false;
    }
}
export function validaPasswordArray(cadena) {
    const errores = []; // Arreglo para almacenar los errores

    if (cadena === undefined || cadena === null) {
        errores.push("La cadena no puede ser nula o indefinida.");
        return errores; // Retorna los errores si la cadena es inválida
    }

    // Verificar cada criterio y agregar mensajes de error si no se cumplen
    if (!/[a-z]/.test(cadena)) {
        errores.push("Al menos una minúscula"); // Error si no hay minúsculas
    }

    if (!/[A-Z]/.test(cadena)) {
        errores.push("Al menos una mayúscula"); // Error si no hay mayúsculas
    }

    if (!/\d/.test(cadena)) {
        errores.push("Al menos un número"); // Error si no hay números
    }

    if (cadena.length > 0 && cadena.length < 8) {
        errores.push("Mínimo 8 caracteres"); // Error si la longitud es menor a 8
    }

    return errores;
}

export function downloadCsv(data, filename = 'datos.csv') {
    // Verificamos compatibilidad con Blob
    if (!window.Blob || !window.URL) {
        alert("Su navegador no permite esta acción");
        return;
    }

    // Convertimos los datos a formato CSV
    const csvContent = [
        Object.keys(data[0]).join(","), // Cabecera
        ...data.map(row => Object.values(row).join(",")) // Filas
    ].join("\n");

    // Creamos el blob
    const blob = new Blob(["\ufeff", csvContent], { type: 'text/csv;charset=utf-8;' });

    // Creamos un enlace para descargar
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;

    // Simulamos un clic en el enlace para iniciar la descarga
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export function downloadFile(route, name) {
    const fileUrl = route;
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Limpiar el DOM

}

export const compareDateWithNow = (inputDate, daysToAdd) => {
    if (!inputDate) {
        return 'Fecha no proporcionada';
    }
    console.log("inputDate", inputDate)
    console.log("daysToAdd", daysToAdd)


    const currentDate = new Date();
    const newDate = addDays(new Date(inputDate), Number(daysToAdd));

    console.log("newDate", newDate)
    const diference = compareAsc(newDate, currentDate);
    console.log("comparisonResult", diference)

    if (diference < 0) {
        return true;
    } else if (diference > 0) {
        return false;
    }
};

export function detectLink(cadena) {
    const regex = /(hhtp|https?:\/\/[^\s]+)/g;
    return cadena.replace(regex, (url) => `<a href="${url}" target="_blank" rel="noopener" style="color: blue;">${url}</a>`);
}

export function formatDateCit(fechaStr) {
    // Eliminar la parte 'Z' si está presente
    const fechaSinZ = fechaStr.replace('Z', '');

    // Separar la fecha y la hora utilizando 'T'
    const [fecha, hora] = fechaSinZ.split('T');

    // Separar año, mes y día
    const [anio, mes, dia] = fecha.split('-');

    // Formatear y devolver la fecha en el formato deseado
    return `${dia}-${mes}-${anio} ${hora}`;
}