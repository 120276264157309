import { logout } from "@/service/LoginService";
import { onBeforeUnmount, ref } from "vue";
import { logoutClear } from "./UtilsExports";

let timeoutId = null;
let checkIntervalId = null; // ID for the check interval
let timeout = null;
let inactive = false;

const inactivityTime = ref(0);

onBeforeUnmount(() => {
    clear();
    inactivityTime.value = 0;
});

const start = () => {
    inactivityTime.value++;
    // console.log("Chequeando inactividad...", inactivityTime.value);
    //  console.log("timeout inactividad...", timeout);
    if (inactivityTime.value >= timeout) {
        console.log("inactivo...", inactivityTime.value);
        resetlogout();
        clear();
    }
};


const reset = () => {
    clearTimeout(timeoutId);
    inactive = false; // Reset inactive state
    inactivityTime.value = 0; // Reset inactivity time
    start(); // Restart the inactivity timer
};

const clear = () => {
    clearTimeout(timeoutId);
    clearInterval(checkIntervalId);
    document.removeEventListener("mousemove", reset, false);
    document.removeEventListener("mousedown", reset, false);
    document.removeEventListener("keypress", reset, false);
    document.removeEventListener("touchmove", reset, false);
};

const setup = (time) => {
    if (!time) return false;
    timeout = time; // Set the timeout duration
    clear(); // Clear existing timers and intervals

    if (isLoggedIn()) {
        checkIntervalId = setInterval(() => {
            start();
        }, 1000);

        // Add event listeners to reset the timer on user interaction
        document.addEventListener("mousemove", reset, false);
        document.addEventListener("mousedown", reset, false);
        document.addEventListener("keypress", reset, false);
        document.addEventListener("touchmove", reset, false);
    }
};

const isLoggedIn = () => {
    return localStorage.getItem("accessToken") !== null;
};

const resetlogout = async () => {
    await handleLogout();
    reset(); // Reset the inactivity timer and state
    logoutClear();
};

async function handleLogout() {
    try {
        await logout().then(async (response) => {
            resetlogout();
        });
    } catch (error) {
        console.error('Error:', error);
        resetlogout();
    }
}

const CheckInactivity = {
    start,
    reset,
    setup,
    resetlogout,
};

export default CheckInactivity;