export default {
    install(app) {
        if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'production_bpec'
            || process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'production_saasmt') { //change pro
            console.log = () => { };
            console.debug = () => { };
            console.info = () => { };
            console.warn = () => { };
            console.error = () => { };
        }
    }
};