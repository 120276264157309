import { clientFindColorsConfigOfTime, clientSetting } from "@/service/ClientService";
import { handleError } from "@/utils/exceptions";
import { defineStore } from 'pinia';

export const useSettingsStore = defineStore('settings', {
    state: () => ({
        settings: {},
        settingsLoaded: false,
    }),
    actions: {
        async loadSettings() {
            try {
                const response = await clientSetting(localStorage.getItem('clientId'));
                if (response.status === 200) {
                    this.settings = response.data.content.settings.reduce((acc, setting) => {
                        acc[setting.name] = setting.value;
                        return acc;
                    }, {});
                    this.settingsLoaded = true; // Marca los ajustes como cargados
                } else {
                    console.warn(`Error: Received status ${response.status}`);
                }
            } catch (error) {
                const msgError = handleError(error);
                console.log(msgError); // Manejo de errores
            }
            //Configuration de color 
            try {
                const response = await clientFindColorsConfigOfTime();
                if (response.status === 200) {
                    console.log(response.data.color_config)
                    this.setSetting('color_config', response.data.color_config);
                }
            } catch (error) {
                const msgError = handleError(error);
                console.log(msgError); // Manejo de errores
            }
        },
        getSetting(name) {
            return this.settings[name] || null; // Devuelve el valor del ajuste
        },
        getSettingAll() {
            return this.settings; // Devuelve el valor del ajuste
        },
        setSetting(name, value) {
            if (this.settings.hasOwnProperty(name)) {
                this.settings[name] = value; // Actualiza el valor del ajuste
            } else {
                this.settings[name] = value; // Actualiza el valor del ajuste
                console.warn(`Setting ${name} does not exist and add`); // Advertencia si el ajuste no existe
            }
        },
        resetSettings() {
            this.settings = {}; // Restablece los ajustes a un objeto vacío
            this.settingsLoaded = false; // Marca como no cargados
            console.log("Settings have been reset."); // Mensaje de confirmación
        }
    },
    // Método que se ejecuta al inicializar el store
    persist: true, // Si deseas persistir el estado entre recargas
});
