import axiosInstance from "@/plugins/axios";

export const getParentClient = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosInstance.get(`/api/users/parentClient`)
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};
export const getUserInfo = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosInstance.get(`/api/users/userInfo/${localStorage.getItem("clientId")}/client`)
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};
export const passwordChange = (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosInstance.put(`/api/users/passwordChange`, data);
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};
export const createIfUnexistent = (formDataService) => {
    return new Promise(async (resolve, reject) => {
        const body = {
            firstName: formDataService.firstName,
            lastName: formDataService.lastName,
            phoneNumber: formDataService.phoneNumber,
            email: formDataService.email,
        }
        try {
            const response = await axiosInstance.post(`/api/users/createIfUnexistent`, body)
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};
export const getUsers = (formData) => {
    if (formData.queryString !== null) {
        const query = formData.queryString.trimEnd();
        formData.queryString = query;
    }
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosInstance.post(`/api/users/usersService`, formData)
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};
export const userBranches = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosInstance.get(`/api/users/userBranches`)
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};

export const userRoles = (type) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosInstance.get(`/api/users/userRoles/${type}`)
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};
export const userValidateDataUser = (formData) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosInstance.post(`/api/users/validateDataUser`, formData)
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};
export const create = (formData) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosInstance.post(`/api/users/create`, formData)
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};
export const divisionsByHierarchyId = (formData) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosInstance.get(`/api/users/divisionsByHierarchyId/${formData.hierarchyId}`)
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};
export const userById = (id) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosInstance.get(`/api/users/userById/${id}`)
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};
export const update = (formData) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosInstance.post(`/api/users/update`, formData)
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};
export const changePasswordFromSecurity = (formData) => {
    const body = {
        password: formData.password,
        passwordConfirmation: formData.passwordConfirmation,
        id: formData.id
    }
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosInstance.post(`/api/users/changePasswordFromSecurity`, body);
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};
export const userEliminate = (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosInstance.post(`/api/users/eliminate/${data}`);
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};
export const viewRol = (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosInstance.get(`/api/users/viewRol/${data}`);
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};
export const viewEdit = (views, formData) => {
    const body = {
        id: formData.id,
        views: views,
        typeUser: formData.type
    }
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosInstance.post(`/api/users/viewEdit`, body);
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};
export const eliminateAll = (formData) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosInstance.post(`/api/users/eliminateAll`, formData);
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};
export const userExportCSV = (formData) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosInstance.post(`/api/users/userExportCSV`, formData);
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};
export const userUploadCSV = (file) => {
    const formData = new FormData();
    formData.append('file', file);
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosInstance.post(`/api/users/userUploadCSV`, formData);

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};
export const userDeleteAllByCSV = (file) => {
    const formData = new FormData();
    formData.append('file', file);
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosInstance.post(`/api/users/userDeleteAllByCSV`, formData);

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};
