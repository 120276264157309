import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

import '@/assets/styles.scss';
import '@/assets/tailwind.css';
import { useSettingsStore } from '@/store/useSettingsStore';
import Aura from '@primevue/themes/aura';
import { createPinia } from 'pinia';
import persistedState from 'pinia-plugin-persistedstate';
import PrimeVue from 'primevue/config';
import ConfirmationService from 'primevue/confirmationservice';
import ToastService from 'primevue/toastservice';
import locale from './locales/es.json';
import { interceptors } from './plugins/axios.config';
import disableConsole from './plugins/disable-console';
import CheckInactivity from './utils/inactivity';


const app = createApp(App);
const pinia = createPinia();
pinia.use(persistedState);
app.use(disableConsole);
interceptors();
app.use(pinia);
const initSettingsStore = useSettingsStore();
const time = initSettingsStore.getSetting("sincola.clients.inactivity.web")
const timeInactivity = time === null ? 3600 : time;
app.use(router);
app.use(PrimeVue, {
    theme: {
        preset: Aura,
        options: {
            darkModeSelector: '.app-dark'
        }
    },
    locale: locale.es
});
CheckInactivity.setup(timeInactivity);

app.use(ToastService);
app.use(ConfirmationService);

app.mount('#app');
