import Translator from "./Translator";


export const handleError = (error) => {
    try {
        let errorMsg = error;
        const translator = new Translator();
        if (error !== undefined && error.response !== null
            && error.response.data !== undefined && error.response.data !== null) {
            errorMsg = error.response.data.errors;
        }
        return translator.translate(errorMsg)
        console.log('Error -->: ', error);
    } catch (error) {
        console.log('Error -->: ', error);
        return 'Error al procesar su solicitud'
    }
};

