// Translator.js
export default class Translator {
    constructor() {
        this.translations = {
            'email not found': 'El correo ingresado no corresponde a ningún operador',
            'null': 'No se puede transferir operador',
            'operator found': 'El operador correspondiente al correo ingresado, ya está asignado a tu sucursal',
            'gate is occuped': 'Taquilla ocupada',
            'ingrese su email y su contraseña': 'Usuario o contraseña inválidos',
            'Operator status correctly updated': 'Estado del operador correctamente actualizado',
            '401 Unauthorized': 'No tiene permisos para procesar su solicitud',
            'Online': 'Conectado',
            'Offline': 'Desconectado',
            'La razón del tiquet es un campo obligatorio': 'El motivo del ticket es un campo obligatorio',
            'genericLoginError': 'Ingrese su email y su contraseña',
            'genericOAuth2Error': 'No pudimos procesar su solicitud de inicio de sesión. Para iniciar sesión debes ingresar el mismo correo en SinCola y en el sistema de tu empresa.',
            'sessionExpired': 'Tu sesión ha expirado, intenta de nuevo',
            'PriorityByProportion': 'Prioridad fija',
            'MaxProportionSelection': 'Prioridad por grupos de servicios',
            'SpecialFirstLongestWaitingTimeSecond': 'Prioridad servicios especiales',
            'SpecialFirstProportionOfSelection': 'Prioridad servicios especiales por proporción',
            'ProportionOfSelection': 'Prioridad por proporción',
            'RoundRobin': 'Primero de cada servicio',
            'LongestWaitingTimeFirst': 'Tiempo de espera más largo primero',
            'ExpressFirstLongestWaitingTimeSecond': 'Prioridad turnos QR',
            'messageSearchTicketNotFound': "No se encontraron tickets que coincidan con los datos de búsqueda",
            'Manager': 'Manager',
            'Supervise': 'Monitor',
            'Operator': 'Operador',
            'Viewer': 'Visualizador',
            'Security': 'Seguridad',
            'operated-gates': 'Mis taquillas',
            'my-lines': 'Mis colas',
            'my-attended-tickets': 'Mis tickets atendidos',
            'my-service-provider': 'Crear tickets',
            'kiosks-monitoring': 'Monitoreo de kioskos',
            'my-clients': 'Mis clientes',
            'optimal-staffing': 'Plantilla optima',
            'dashboard': 'Tablero',
            'reports': 'Reportes',
            'operators': 'Operadores',
            'my-branches': 'Mis sucursales',
            'my-tickets-reassigned': 'Mis tickets reasignados',
            'bad_current_pass': 'Tu contraseña actual no es correcta',
            'pass_sequence_limit': 'Tu contraseña no puede contener una sequencia de caracteres de la contraseña actual',
            'pass_equal_nickname': 'Tu contraseña no puede contener el login o nickname',
            'bad_current_pass': 'Tu contraseña actual no es correcta',
            'error_pass_used': 'La nueva contraseña ha sido utilizada anteriormente',
            'error_pass_current': 'La nueva contraseña es igual a la contraseña actual',
            'pass_used': 'La nueva contraseña ha sido utilizada anteriormente',
            'proximity': 'Proximidad',
            'turn': 'Turno llamado',
            'cancel.system': 'Turno cancelado',
            'postpone': 'Turno pospuesto',
            'create.none': 'Nuevo turno sin personas por delante',
            'create.single': 'Nuevo turno con una persona por delante',
            'create.plural': 'Nuevo turno con personas por delante',
            'create.remote_inactive': 'Recordatorio turno agendado',
            'create.remote_inactive': 'Recordatorio turno agendado',
            'create.remote_scheduled': 'Turno agendado',
            'activation_reminder': 'Turno agendado sms, para determinada hora',
            'email.activation_reminder': 'Turno agendado email ,para determinada hora',
            'cancel.system': 'Turno cancelado , cierre taquilla',
            'cancel.user': 'Turno cancelado por el usuario',
            'message_poll': 'Atención recibida',
            'email.message_poll': 'Turno atendido experiencia recibida'
        };
    }

    translate(text) {
        return this.translations[text] || text;
    }
}
